import React, { useMemo } from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { BudgetInterface } from '@src/interfaces/budgets'
import { BudgetStats, RowInterface } from '@src/interfaces/data'
import { EntityTypes } from '@src/constants/api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router'
import { Box, Cell, chain, Flex, Link } from '@revolut/ui-kit'
import TalentHeader from '@src/pages/Forms/CommonTalentTab/TalentHeader'
import { TalentType } from '@src/interfaces/talent/talent'
import {
  SummarySubheader,
  SummaryTableContainer,
  summaryTableProps,
} from '@src/features/Summary/common'
import AdjustableTable from '@components/Table/AdjustableTable'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { PermissionTypes } from '@src/store/auth/types'
import {
  budgetAnnualExpenseColumn,
  budgetEmployeeNameColumn,
  getBudgetPerfLabelReviewColumn,
  budgetRoleColumn,
  budgetSeniorityColumn,
  budgetTeamNameColumn,
} from '@src/constants/columns/budgets'
import { withoutFilterAndSort } from '@src/utils/table'
import { TableNames } from '@src/constants/table'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'

export interface TalentSummaryProps {
  data: DepartmentInterface | TeamInterface
  table: useTableReturnType<BudgetInterface, BudgetStats>
  entityType: EntityTypes
}

type HiddenCells = Partial<Record<keyof BudgetInterface, boolean>>

const getRow = (
  entityType: EntityTypes,
  gradesMap: GradesMapInterface,
): RowInterface<BudgetInterface> => ({
  cells: withoutFilterAndSort([
    {
      ...budgetEmployeeNameColumn,
      width: 120,
    },
    {
      ...budgetSeniorityColumn,
      width: 100,
    },
    {
      ...budgetRoleColumn,
      width: 180,
    },
    ...(entityType !== EntityTypes.team
      ? [
          {
            ...budgetTeamNameColumn,
            width: 140,
          },
        ]
      : []),
    {
      ...budgetAnnualExpenseColumn,
      masked: true,
      width: 80,
    },
    {
      ...getBudgetPerfLabelReviewColumn(gradesMap),
      masked: true,
      width: 80,
    },
  ]),
})

const getFormUrl = (entityType: EntityTypes, params: { id?: string }): string => {
  switch (entityType) {
    case EntityTypes.department:
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.TALENT.GENERAL, params)
    case EntityTypes.team:
      return pathToUrl(ROUTES.FORMS.TEAM.TALENT.GENERAL, params)
    default:
      return ''
  }
}

const talentType = {
  [EntityTypes.department]: TalentType.Department,
  [EntityTypes.team]: TalentType.Team,
}

const maxItemsToShow = 5

const TalentSummary = ({ data, table, entityType }: TalentSummaryProps) => {
  const params = useParams<{ id?: string }>()
  const formUrl = getFormUrl(entityType, params)
  const gradesMap = useGetReviewGradesMap()

  const row = useMemo(() => getRow(entityType, gradesMap), [entityType])

  const hasExpensesPermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeeExpenses,
  )
  const hasPerformancePermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeePerformance,
  )

  const hiddenCells: HiddenCells = {
    annual_expense: !hasExpensesPermission,
    performance_label: !hasPerformancePermission,
  }

  const onCurrencyChange = (currency: string) => {
    table.onFilterChange({
      filters: [{ name: currency, id: currency }],
      columnName: 'target_currency',
      nonResettable: true,
    })
  }

  return (
    <>
      <SummarySubheader formUrl={formUrl}>Talent</SummarySubheader>
      <Cell>
        <Flex flexDirection="column" width="100%">
          <TalentHeader
            id={data.id}
            filterBy={[]}
            tableStats={table.stats}
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            type={talentType[entityType]}
            showExpensesStats={hasExpensesPermission}
            showPerformanceStats={hasPerformancePermission}
            onCurrencyChange={onCurrencyChange}
            period="annual"
            showExpandedPerformanceStats
          />
          <SummaryTableContainer>
            <AdjustableTable<BudgetInterface, BudgetStats>
              name={TableNames.TalentSummary}
              row={row}
              hiddenCells={hiddenCells}
              {...table}
              data={table.data.slice(0, maxItemsToShow)}
              {...summaryTableProps}
              hideCountAndButtonSection
            />
          </SummaryTableContainer>
          {table.data.length > maxItemsToShow && (
            <Box pt="s-12">
              <Link fontWeight={500} use="a" href={formUrl} target="_blank">
                {chain('Show all', table.count)}
              </Link>
            </Box>
          )}
        </Flex>
      </Cell>
    </>
  )
}

export default TalentSummary
