import React, { RefObject, useRef } from 'react'
import { FinalGrade, ManagerRecommendationInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Avatar,
  Badge,
  Box,
  Flex,
  Text,
  Token,
  useTooltip,
  Tooltip as UIKitTooltip,
  VStack,
  Widget,
  Item,
} from '@revolut/ui-kit'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import RecommendationGrade from '@src/pages/Forms/EmployeePerformance/components/RecommendationGrade'
import { ChevronRight } from '@revolut/icons'
import { useGetReviewGradesMap } from '@src/utils/grades'

interface Props {
  isViewMode?: boolean
  recommendationData?: ManagerRecommendationInterface
  renderJustification: () => React.ReactNode
  renderError?: () => React.ReactNode | null
  onRadioChange?: (value: FinalGrade) => void
  onFeedbackClick?: () => void
  isSelfReview: boolean
  reviews?: ManagerRecommendationInterface[]
  feedbackContent: React.ReactNode
  recommendationRef?: RefObject<HTMLDivElement>
}

const GradeRatingWithTooltip = ({
  option,
  isViewMode,
  items,
}: {
  option: { key: FinalGrade; items: string[] }
  isViewMode: boolean
  items?: ManagerRecommendationInterface[]
}) => {
  const ratingTooltip = useTooltip()
  const gradesMap = useGetReviewGradesMap()

  return (
    <Flex gap="s-8" {...ratingTooltip.getAnchorProps()} width="fit-content">
      <RecommendationGrade value={option.key} fontWeight={500} pb="s-8" />
      {isViewMode && !!items?.length && (
        <>
          <Badge
            minWidth="s-20"
            height="s-16"
            mt="s-2"
            backgroundColor={Token.color.deepGrey}
            style={{ fontWeight: 500 }}
          >
            {items.length}
            <UIKitTooltip
              {...ratingTooltip.getTargetProps()}
              maxWidth={200}
              placement="right-start"
            >
              <VStack>
                <Text variant="small" fontWeight="bold">
                  {gradesMap[option.key]}
                </Text>
                {items.map(item => (
                  <Text key={item.reviewer?.id} variant="small">
                    {item.reviewer?.full_name}
                  </Text>
                ))}
              </VStack>
            </UIKitTooltip>
          </Badge>
        </>
      )}
    </Flex>
  )
}

export const FeedbackCommon = connect(
  ({
    isViewMode = false,
    recommendationData,
    reviews,
    onFeedbackClick,
    renderError,
    renderJustification,
    onRadioChange,
    isSelfReview,
    feedbackContent,
    recommendationRef,
  }: Props) => {
    const tooltipAnchor = useRef<HTMLDivElement[]>([])
    const performanceOptions = recommendationData?.employee_project_performance.options

    return (
      <Widget>
        <Item
          use={isViewMode ? undefined : 'button'}
          onClick={e => {
            e.preventDefault()
            onFeedbackClick?.()
          }}
        >
          <Item.Avatar>
            <Avatar useIcon="40/SubtractStar" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Feedback</Item.Title>
          </Item.Content>
          {!isViewMode && (
            <Item.Side>
              <ChevronRight color={Token.color.greyTone50} />
            </Item.Side>
          )}
        </Item>
        <Box p="s-16">
          <VStack space="s-16">
            {recommendationData && (
              <>
                <Text variant="primary" ref={recommendationRef}>
                  {recommendationData.employee_project_performance.name}
                </Text>
                {performanceOptions?.map((option, optionIdx) => {
                  const items = reviews?.filter(
                    item => item.employee_project_performance.value === option.key,
                  )
                  return (
                    <Box
                      key={optionIdx}
                      px="s-16"
                      mb="s-16"
                      data-testid={`feedback-${option.key}`}
                    >
                      <Flex>
                        <RadioButton
                          label={
                            <div>
                              <Box p="s-12">
                                <GradeRatingWithTooltip
                                  option={option}
                                  isViewMode={isViewMode}
                                  items={items}
                                />
                                <ul
                                  style={{
                                    paddingInlineStart: 16,
                                    margin: 0,
                                    color: Token.color.greyTone50,
                                  }}
                                >
                                  {option.items.map((item, i) => (
                                    <li key={i}>
                                      <Text
                                        variant="caption"
                                        color={Token.color.greyTone50}
                                      >
                                        {item}
                                      </Text>
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            </div>
                          }
                          checked={
                            isViewMode
                              ? !!items?.length
                              : recommendationData.employee_project_performance.value ===
                                option.key
                          }
                          disabled={isViewMode}
                          onChange={() => onRadioChange?.(option.key)}
                          inputRef={el => {
                            tooltipAnchor.current[optionIdx] = el
                          }}
                          alignTop
                          capitalize={false}
                        />
                      </Flex>
                    </Box>
                  )
                })}
                {renderError?.()}
                {renderJustification()}
              </>
            )}
            <VStack mt="s-8">
              {!isSelfReview && (
                <Text variant="primary" mb={isViewMode ? '-s-8' : 's-8'}>
                  What feedback do you want to provide?
                </Text>
              )}
              {feedbackContent}
            </VStack>
          </VStack>
        </Box>
      </Widget>
    )
  },
)
