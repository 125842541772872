import React, { useEffect, useRef, useState } from 'react'
import {
  FinalGrade,
  ManagerRecommendationInterface,
  ReviewDataInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import set from 'lodash/set'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import { useLocation } from 'react-router-dom'
import BottomText from '@components/Inputs/partials/BottomText'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { FeedbackCommon } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/FeedbackCommon'
import { useGetProbationMissingValues } from '@src/pages/Forms/ProbationLayout/utils'
import { shouldScrollToError } from '@src/pages/Forms/EmployeePerformanceLayout/utils'

export interface FeedbackProps {
  type?: RecommendationTypes
  recommendationData?: ManagerRecommendationInterface
  reviews?: ManagerRecommendationInterface[]
  onFeedbackClick?: () => void
  showBeforeSubmitCheckError?: boolean
}

export const Feedback = connect(
  ({
    recommendationData,
    type,
    reviews,
    onFeedbackClick,
    showBeforeSubmitCheckError,
  }: FeedbackProps) => {
    const { values, errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()
    const [prosElement, setProsElement] = useState<HTMLTextAreaElement | null>(null)
    const ref = useRef<HTMLDivElement>(null)
    const { hash } = useLocation()

    const reviewData = values.review_data
    const formErrors = errors as FormError<ReviewScorecardInterface>

    const user = useSelector(selectUser)
    const reviewedEmployeeId = values.reviewed_employee.id
    const isSelfReview = reviewedEmployeeId === user.id
    const { missingRating, missingBarRaiser } = useGetProbationMissingValues(values)

    useEffect(() => {
      if (!(reviewData as ReviewDataInterface)?.overall_feedback) {
        set(values, 'review_data.overall_feedback', {
          pros: [],
          cons: [],
        })
      }
    }, [])

    useEffect(() => {
      if (submitFailed || missingRating) {
        let key

        switch (type) {
          case 'lm':
            key = `review_data.line_manager_extra_section`
            break

          case 'fm':
            key = `review_data.functional_manager_extra_section`
            break

          case 'peer':
            key = `review_data.peer_extra_section`
            break
        }

        const scrollToMissingRating =
          showBeforeSubmitCheckError && missingRating && !missingBarRaiser
        const scrollToValidationError = shouldScrollToError(
          errors,
          `${key}.employee_project_performance.value`,
        )

        if (scrollToMissingRating || scrollToValidationError) {
          ref?.current?.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, [
      formErrors.review_data,
      missingRating,
      missingBarRaiser,
      showBeforeSubmitCheckError,
    ])

    useEffect(() => {
      if (hash === SCROLL_ERROR_HASH && formErrors.review_data?.overall_feedback) {
        !!prosElement && prosElement.focus()
      }
    }, [hash])

    const onRadioChange = (value: FinalGrade) => {
      let key

      switch (type) {
        case 'lm':
          key =
            'review_data.line_manager_extra_section.employee_project_performance.value'
          break

        case 'fm':
          key =
            'review_data.functional_manager_extra_section.employee_project_performance.value'
          break

        case 'peer':
          key = 'review_data.peer_extra_section.employee_project_performance.value'
          break

        case 'pip':
          key =
            'review_data.recommendation_extra_section.employee_project_performance.value'
          break
      }

      if (key) {
        set(values, key, value)
      }
    }

    const renderError = () => {
      if (!submitFailed && !missingRating) {
        return null
      }

      let key

      switch (type) {
        case 'lm':
          key = `review_data.line_manager_extra_section.employee_project_performance.value`
          break

        case 'fm':
          key = `review_data.functional_manager_extra_section.employee_project_performance.value`
          break

        case 'peer':
          key = `review_data.peer_extra_section.employee_project_performance.value`
          break
      }

      const errorMsg = <BottomText error="Please select one of the options" />

      if (showBeforeSubmitCheckError && missingRating) {
        return errorMsg
      }

      if (!key || !get(errors, key) || get(values, key)) {
        return null
      }

      return errorMsg
    }

    const renderJustification = () => {
      let textAreaName: string = ''

      switch (type) {
        case 'lm':
          textAreaName =
            'review_data.line_manager_extra_section.employee_project_performance.justification'
          break

        case 'fm':
          textAreaName =
            'review_data.functional_manager_extra_section.employee_project_performance.justification'
          break

        case 'peer':
          textAreaName =
            'review_data.peer_extra_section.employee_project_performance.justification'
          break

        case 'pip':
          textAreaName =
            'review_data.recommendation_extra_section.employee_project_performance.justification'
          break
      }

      return (
        <LapeNewTextArea
          label="Grade justification for calibration"
          name={textAreaName}
        />
      )
    }

    const feedbackContent = (
      <OverallFeedback
        noMargin
        pros={(reviewData as ReviewDataInterface)?.overall_feedback?.pros}
        cons={(reviewData as ReviewDataInterface)?.overall_feedback?.cons}
        onChangePros={val => {
          set(values, 'review_data.overall_feedback.pros', val.split('\n'))
        }}
        onChangeCons={val => {
          set(values, 'review_data.overall_feedback.cons', val.split('\n'))
        }}
        onGetProsElement={elem => {
          setProsElement(elem)
        }}
      />
    )

    return (
      <FeedbackCommon
        recommendationData={recommendationData}
        renderJustification={renderJustification}
        onRadioChange={onRadioChange}
        renderError={renderError}
        reviews={reviews}
        isSelfReview={isSelfReview}
        onFeedbackClick={onFeedbackClick}
        feedbackContent={feedbackContent}
        recommendationRef={ref}
      />
    )
  },
)
