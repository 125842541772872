import React from 'react'
import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import { FinalGrade } from '@src/interfaces/performance'
import GradeSelect, {
  GradeSelectProps,
} from '@src/pages/Performance/SupportTool/GradeSelect'

interface Props extends GradeSelectProps {
  locked?: boolean
}

const CalibratedGradeColumn = ({
  locked,
  value,
  grades,
  onChange,
  comment,
  readOnly,
  gradesMap,
}: Props) => {
  if (locked) {
    return <GradeLockIcon />
  }

  return (
    <GradeSelect
      value={value || FinalGrade.Empty}
      grades={grades}
      onChange={onChange}
      comment={comment}
      readOnly={readOnly}
      gradesMap={gradesMap}
    />
  )
}

export default CalibratedGradeColumn
