import * as React from 'react'
import { useRef, useState } from 'react'
import { Field } from 'react-final-form'
import uniqBy from 'lodash/uniqBy'
import styled from 'styled-components'
import { required as requiredValidator } from '../../../utils/form'
import isObject from 'lodash/isObject'
import Tooltip from '../../Tooltip/Tooltip'
import { getScorecardRadioHeaders, ScorecardRadioValues } from '@src/interfaces/scorecard'
import { SmartEllipsis } from '../partials/SmartEllipsis/SmartEllipsis'
import Dialog from '../../Modals/Dialog/Dialog'
import Button from '../../Button/Button'
import { RadioButton } from '../RadioButtons/RadioButtons'
import { colorGetter } from '@src/styles/colors'
import { Color, Token } from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import { useGetReviewGradesMap } from '@src/utils/grades'

const LabelWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 396px;
  align-content: center;
  justify-content: space-between;
  padding-left: 10px;
  background-color: ${Token.color.background};
  border-bottom: 1px solid ${colorGetter(Color.GREY_20_OPAQUE_50)};
  border-left: 1px solid ${colorGetter(Color.GREY_20_OPAQUE_50)};
  border-right: 1px solid ${colorGetter(Color.GREY_20_OPAQUE_50)};

  &:nth-child(2) {
    border: 1px solid ${colorGetter(Color.GREY_20_OPAQUE_50)};
  }
`

const LabelContainer = styled.div<{ info: boolean }>`
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: ${props => (props.info ? 'visible' : 'hidden')};
`

const InputContainer = styled.div`
  display: flex;
`

const Error = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 5px;
  line-height: 12px;
  grid-column: 1/3;
  color: ${props => props.theme.colors.red};
`

const RadioSectionContainer = styled.div<{ last?: boolean }>`
  display: flex;
  background-color: ${props =>
    props.last ? props.theme.colors['grey-tone-2'] : 'transparent'};
  padding: 6px 19px;
  justify-content: flex-end;
  align-items: center;
`

const ThumbContainer = styled.div`
  position: relative;
  padding: 6px;
  border-radius: 50%;
  &:hover {
    opacity: 0.8;
  }
`

const NumberOfReviewsContainer = styled.div<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.blue};
  position: absolute;
  padding-top: 1px;
  right: 26px;
`

const NumberOfReviews = styled.div<{ isZero: boolean }>`
  opacity: ${props => (props.isZero ? '0' : '1')};
  font-size: 10px;
  cursor: default;
  font-weight: bold;
`

const OneOptionReview = styled.div`
  padding: 11px;
  color: ${Token.color.background};
`
const OneOptionTitle = styled.div`
  white-space: nowrap;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: capitalize;
`

const OneOptionReviewCounts = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 4px;
  margin-bottom: 8px;
  justify-content: left;

  &:last-child {
    margin-bottom: 0;
  }
`

const LabelWithInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const OneOptionReviewCountsNumber = styled.div``

const OneOptionReviewName = styled.div`
  white-space: nowrap;
`

const SmartLabel = styled(SmartEllipsis)`
  align-self: center;
`

const TooltipContainer = styled.div`
  color: ${Token.color.background};
  display: grid;
  grid-row-gap: 12px;
  width: 335px;
  padding: 12px;
`
const TooltipTitle = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`
const TooltipValueContainer = styled.div`
  font-size: 11px;
`
const TooltipValueTitle = styled.div`
  display: inline-block;
  margin-right: 5px;
  font-weight: bold;
`
const WarningDialog = styled(Dialog)`
  max-width: 420px;
`
const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`
const MainText = styled.div`
  font-size: 16px;
  margin-bottom: 25px;
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  required?: boolean
  info?: InfoTooltipType
  displayName?: string
  editing?: boolean
  label: string
  error?: string
}

interface RadioProps {
  onChange: (e: string) => void
  editing?: boolean
  last?: boolean
  selected:
    | string
    | Map<
        ScorecardRadioValues,
        {
          full_name: string
          date: string
        }[]
      >
  value: ScorecardRadioValues
}

interface OneOptionReviewsTooltipProps {
  values: {
    full_name: string
    date: string
  }[]
  type: ScorecardRadioValues
}

interface InfoTooltipType {
  title: string
  values: {
    title: string
    value: string
  }[]
}

const OneOptionReviewsTooltip = ({ values, type }: OneOptionReviewsTooltipProps) => {
  const uniqValues = uniqBy(values, value => value.full_name)
  const gradesMap = useGetReviewGradesMap()
  return (
    <OneOptionReview>
      <OneOptionTitle>{getScorecardRadioHeaders(gradesMap)[type]}</OneOptionTitle>
      <div>
        {uniqValues.map(value => {
          return (
            <OneOptionReviewCounts key={value.full_name}>
              <OneOptionReviewCountsNumber>
                {values.filter(v => v.full_name === value.full_name).length}
              </OneOptionReviewCountsNumber>
              <OneOptionReviewName>{value.full_name}</OneOptionReviewName>
            </OneOptionReviewCounts>
          )
        })}
      </div>
    </OneOptionReview>
  )
}

const Radio = ({ onChange, last, selected, value, editing }: RadioProps) => {
  const theme = useTheme()
  const numberRef = useRef<HTMLDivElement>(null)

  return (
    <RadioSectionContainer last={last}>
      <Tooltip
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        body={<OneOptionReviewsTooltip values={selected[value]} type={value} />}
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        hide={!isObject(selected) || !selected[value] || !selected[value]?.length}
        anchor={numberRef?.current || undefined}
        placement="left"
      >
        <ThumbContainer>
          <NumberOfReviewsContainer color={last ? theme.colors.foreground : undefined}>
            {isObject(selected) && (
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              <NumberOfReviews ref={numberRef} isZero={!selected[value]?.length}>
                {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
                {selected[value]?.length || 0}
              </NumberOfReviews>
            )}
          </NumberOfReviewsContainer>
          <RadioButton
            color={last ? theme.colors.foreground : undefined}
            disabled={!editing}
            onChange={() => {
              onChange(value)
            }}
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            checked={selected === value || (!editing && selected[value])}
          />
        </ThumbContainer>
      </Tooltip>
    </RadioSectionContainer>
  )
}

const InfoTooltip = ({ info }: { info?: InfoTooltipType }) => {
  if (!info) {
    return null
  }
  return (
    <TooltipContainer>
      <TooltipTitle>{info.title}</TooltipTitle>
      {info.values?.map(value => {
        return (
          <TooltipValueContainer key={value.value}>
            <TooltipValueTitle>{value.title}:</TooltipValueTitle>
            {value.value}
          </TooltipValueContainer>
        )
      })}
    </TooltipContainer>
  )
}

const FinalRadioScores = ({
  name,
  error,
  label,
  required,
  info,
  displayName,
  editing,
}: Props) => {
  const [showWarning, setShowWarning] = useState(false)
  const warnUser = () => {
    setShowWarning(true)
  }

  const onCloseWarning = () => {
    setShowWarning(false)
  }

  return (
    <Tooltip
      hide={!info || !editing || showWarning}
      body={<InfoTooltip info={info} />}
      placement="left"
    >
      <Field<string> name={name} validate={required ? requiredValidator : undefined}>
        {({ input: { onChange, value }, meta }) => (
          <LabelWrapper>
            <WarningDialog open={showWarning} onClose={onCloseWarning}>
              <Title>Think twice</Title>
              <MainText>
                Is {displayName} an {value} case? If so, please provide evidence-based
                feedback in your comment.
              </MainText>
              <Button square onClick={onCloseWarning}>
                Ok
              </Button>
            </WarningDialog>
            <LabelContainer info={!!info}>
              {info ? (
                <LabelWithInfo>{label}</LabelWithInfo>
              ) : (
                <SmartLabel>{label}</SmartLabel>
              )}
              {error || ((meta.error || meta.submitError) && meta.touched) ? (
                <Error>
                  {error || meta.error?.replace(name, 'This') || meta.submitError}
                </Error>
              ) : null}
            </LabelContainer>
            <InputContainer>
              <Radio
                onChange={data => {
                  warnUser()
                  onChange(data)
                }}
                editing={editing}
                selected={value}
                value={ScorecardRadioValues.unsatisfactory}
              />
              <Radio
                onChange={onChange}
                editing={editing}
                selected={value}
                value={ScorecardRadioValues.below_expectations}
              />
              <Radio
                onChange={onChange}
                editing={editing}
                selected={value}
                value={ScorecardRadioValues.meets_expectations}
              />
              <Radio
                onChange={onChange}
                editing={editing}
                selected={value}
                value={ScorecardRadioValues.above_expectations}
              />
              <Radio
                onChange={data => {
                  warnUser()
                  onChange(data)
                }}
                editing={editing}
                selected={value}
                value={ScorecardRadioValues.exceptional}
              />
              <Radio
                onChange={onChange}
                editing={editing}
                selected={value}
                value={ScorecardRadioValues.neutral}
                last
              />
            </InputContainer>
          </LabelWrapper>
        )}
      </Field>
    </Tooltip>
  )
}

export default FinalRadioScores
