import isNumber from 'lodash/isNumber'
import { RevolutTheme } from '@src/styles/theme'
import { PerformanceScores } from '@src/interfaces/scorecard'
import { FinalGrade, ReviewerRelation } from '@src/interfaces/performance'
import { getColor } from '@src/styles/colors'
import { Color } from '@revolut/ui-kit'
import { GradesMapInterface } from '@src/utils/grades'

export enum TabsStates {
  Scores = 'Scores',
  Feedback = 'Feedback',
}

export const TabsOrder = [TabsStates.Scores, TabsStates.Feedback]

export const performanceToString = (
  gradesMap: GradesMapInterface,
  label?: PerformanceScores,
) => {
  switch (label) {
    case PerformanceScores.UNSATISFACTORY:
      return gradesMap[FinalGrade.Poor]
    case PerformanceScores.BELOW_EXPECTATIONS:
    case PerformanceScores.AVERAGE_MINUS:
      return gradesMap[FinalGrade.AverageMinus]
    case PerformanceScores.MEETS_EXPECTATIONS:
    case PerformanceScores.AVERAGE_PLUS:
      return gradesMap[FinalGrade.AveragePlus]
    case PerformanceScores.STRONG:
    case PerformanceScores.ABOVE_EXPECTATIONS:
      return gradesMap[FinalGrade.Strong]
    case PerformanceScores.EXCEPTIONAL:
      return gradesMap[FinalGrade.Exceptional]
    case PerformanceScores.PROBATION_FAILED:
      return 'Probation failed'
    case PerformanceScores.PROBATION_PASSED:
      return 'Probation passed'
    case PerformanceScores.NEUTRAL:
      return 'Neutral'
    default:
      return 'N/A'
  }
}

export const performanceToColor = (theme: RevolutTheme, label?: PerformanceScores) => {
  switch (label) {
    case PerformanceScores.UNSATISFACTORY:
    case PerformanceScores.PROBATION_FAILED:
      return getColor(theme, Color.ERROR)
    case PerformanceScores.BELOW_EXPECTATIONS:
    case PerformanceScores.AVERAGE_MINUS:
      return getColor(theme, Color.WARNING)
    case PerformanceScores.MEETS_EXPECTATIONS:
      return getColor(theme, Color.GREEN_OPAQUE_60)
    case PerformanceScores.AVERAGE_PLUS:
      return getColor(theme, Color.FOREGROUND)
    case PerformanceScores.STRONG:
    case PerformanceScores.ABOVE_EXPECTATIONS:
    case PerformanceScores.PROBATION_PASSED:
      return getColor(theme, Color.GREEN)
    case PerformanceScores.EXCEPTIONAL:
      return getColor(theme, Color.GREEN)
    default:
      return getColor(theme, Color.GREY_TONE_50)
  }
}

export const performanceLabelsToColor = (
  theme: RevolutTheme,
  label: string,
  gradesMap: GradesMapInterface,
) => {
  switch (label) {
    case gradesMap[FinalGrade.Poor]:
    case 'Probation failed':
      return getColor(theme, Color.ERROR)
    case gradesMap[FinalGrade.AverageMinus]:
    case gradesMap[FinalGrade.AveragePlus]:
      return getColor(theme, Color.FOREGROUND)
    case gradesMap[FinalGrade.Strong]:
    case 'Probation passed':
    case gradesMap[FinalGrade.Exceptional]:
      return getColor(theme, Color.GREEN)
    default:
      return getColor(theme, Color.GREY_TONE_50)
  }
}

export const relationToString = (
  relation: ReviewerRelation,
  short?: boolean,
  byDefault = 'Peer',
) => {
  switch (relation) {
    case ReviewerRelation.FunctionalManager:
      return short ? 'FM' : 'Functional Manager'
    case ReviewerRelation.LineManager:
      return short ? 'LM' : 'Line Manager'
    case ReviewerRelation.Self:
      return 'Self'
    case ReviewerRelation.HeadOfFunction:
      return short ? 'HoF' : 'Head of Function'
    case ReviewerRelation.Unknown:
      return ''
    default:
      return byDefault
  }
}

export const percentToColor = (theme: RevolutTheme, num?: number | null) => {
  if (!isNumber(num)) {
    return getColor(theme, Color.GREY_TONE_50)
  }

  if (num >= 70) {
    return getColor(theme, Color.GREEN)
  }
  if (num >= 50) {
    return getColor(theme, Color.GREEN_OPAQUE_60)
  }
  if (num >= 25) {
    return getColor(theme, Color.WARNING)
  }

  return getColor(theme, Color.ERROR)
}
