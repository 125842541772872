import {
  Avatar,
  Box,
  Button,
  Cell,
  Color,
  HStack,
  Skeleton,
  Text,
  TransitionCollapse,
  VStack,
} from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import { EmployeeInterface } from '@src/interfaces/employees'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import React, { useContext, useMemo, useState } from 'react'
import { useTable } from '@src/components/Table/hooks'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import { getNomineePerformanceHistory } from '@src/api/promotions'
import {
  CellTypes,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { PromotionParams } from './common/common'
import { useParams } from 'react-router-dom'
import { talentPerformanceRequests } from '@src/api/talent'
import {
  PerformanceContext,
  PerformanceContextValue,
} from './common/EligibilityAndPerformance/PerformanceContext'
import {
  FinalGrade,
  PerformanceSelector,
  ReviewCategory,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { getFinalGradeNumberToTitle } from '@src/interfaces/scorecard'
import { selectorKeys } from '@src/constants/api'
import { getInverseColorsAndGrade } from '@components/PerformanceGrade/PerformanceGrade'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { BarChart } from '@revolut/icons'
import { useFetchPerformanceSummary } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/hooks'
import PerformanceTable from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceTable'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'
import { getReviewsUrl } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/common'
import { ProgressionSection } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary'
import { usePerformanceTimelineTable } from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceHistory/hooks'
import { PerformanceTimeline } from './PerformanceTimeline'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'

export interface PerformanceDataSidebarProps {
  isOpen: boolean
  onClose: () => void
  employee: EmployeeInterface
  nominationId: string
}
const reviewers = ['LM', 'FM']

const gradeFields: { [key: string]: keyof TalentPerformanceInterface } = {
  LM: 'line_manager_grade_suggestion',
  FM: 'functional_manager_grade_suggestion',
}

const getRow: (gradesMap: GradesMapInterface) => RowInterface<{
  relation: string
  display_grade: FinalGrade | null
}> = gradesMap => ({
  cells: [
    {
      type: CellTypes.text,
      idPoint: 'relation',
      dataPoint: 'relation',
      title: 'Relation',
      selectorsKey: selectorKeys.none,
      filterKey: null,
      sortKey: null,
      width: 160,
    },
    {
      type: CellTypes.insert,
      idPoint: 'display_grade',
      dataPoint: 'display_grade',
      title: 'Recommended grade',
      selectorsKey: selectorKeys.none,
      filterKey: null,
      sortKey: null,
      width: 160,
      colors: data =>
        getInverseColorsAndGrade(gradesMap, data.display_grade)?.color || 'inherit',
      insert: ({ data }) => {
        if (!data.display_grade) {
          return '-'
        }
        const gradeNumber = gradesMap[data.display_grade]
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        return getFinalGradeNumberToTitle(gradesMap)[gradeNumber]
      },
    },
  ],
})

const PerformanceDataSidebar = ({
  employee,
  nominationId,
  lastPublishedCycle,
}: PerformanceDataSidebarProps & {
  lastPublishedCycle: PerformanceSelector
}) => {
  const [showFeedback, setShowFeedback] = useState(false)
  const { id } = useParams<PromotionParams>()
  const getInitialFilter = (employeeId: string | number): FilterByInterface[] => [
    {
      filters: [{ id: employeeId, name: `${employeeId}` }],
      columnName: 'employee__id',
      nonResettable: true,
    },
  ]
  const initialSort: SortByInterface[] = [
    {
      sortBy: 'cycle__offset',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const performanceTimelineTable = usePerformanceTimelineTable(employee)
  const historyTable = useTable<TalentPerformanceInterface>(
    {
      getItems: id
        ? getNomineePerformanceHistory(nominationId)
        : talentPerformanceRequests.getItems,
    },
    getInitialFilter(employee.id),
    initialSort,
    { disableQuery: true },
  )
  const gradesMap = useGetReviewGradesMap()

  const renderSectionTitle = (title: string) => (
    <Text color={Color.GREY_TONE_50} fontWeight={500} fontSize="14px">
      {title}
    </Text>
  )

  const latestGrade: TalentPerformanceInterface | undefined = useMemo(() => {
    return historyTable.data.find(
      grade => Number(grade.cycle?.id) === Number(lastPublishedCycle.id),
    )
  }, [historyTable.data, lastPublishedCycle])

  const recommendations = useMemo(() => {
    if (!latestGrade) {
      return []
    }

    return reviewers.map(reviewer => ({
      relation: reviewer,
      display_grade: latestGrade[gradeFields[reviewer]] as FinalGrade,
    }))
  }, [latestGrade])

  const performanceSummaryTitle = useMemo(() => {
    if (!latestGrade) {
      // means data corrupted, there should be always lastPublishedCycle
      // just for the better dev experience
      return (
        <Text color={Color.GREY_TONE_50}>
          There is no expected performance cycle for this employee
        </Text>
      )
    }

    const gradeAndColor = getInverseColorsAndGrade(gradesMap, latestGrade.display_grade)

    return (
      <HStack space="s-8">
        <Text fontSize="20px" fontWeight={600}>
          Latest Grade:
        </Text>
        <Text fontSize="20px" color={gradeAndColor?.color || ''}>
          {gradeAndColor?.grade || '-'}
        </Text>
        <Text fontWeight={400} fontSize="20px">
          ({lastPublishedCycle.name})
        </Text>
        <BarChart size={24} color={Color.GREY_20} />
      </HStack>
    )
  }, [latestGrade, lastPublishedCycle])

  const { data, isLoading } = useFetchPerformanceSummary(
    String(lastPublishedCycle.id),
    employee.id,
    ReviewCategory.Performance,
    [
      {
        columnName: 'reviewer_relation',
        filters: [
          {
            id: ReviewerRelation.LineManager,
            name: ReviewerRelation.LineManager,
          },
          {
            id: ReviewerRelation.FunctionalManager,
            name: ReviewerRelation.FunctionalManager,
          },
        ],
      },
    ],
    nominationId,
  )

  const performanceTableSection = useMemo(() => {
    if (isLoading) {
      return <Skeleton height={250} borderRadius={12} mt="s-16" />
    }

    if (data?.reviews.length) {
      return (
        <PerformanceTable
          isSidebar
          data={data?.summary}
          cycleId={`${lastPublishedCycle.id}`}
          employeeId={employee.id}
          employeeSeniorityId={employee.seniority?.id}
          category={ReviewCategory.Performance}
        />
      )
    }

    return <NoReviewsWidget />
  }, [isLoading, lastPublishedCycle, data, employee])

  const overalFeedbackSection = useMemo(() => {
    if (data?.summary?.overall_feedbacks.length) {
      return (
        <>
          <TransitionCollapse in={showFeedback}>
            <Box mt="s-24">
              <SidebarFeedbacks
                feedbacks={data.summary.overall_feedbacks}
                ratings={data.summary?.ratings}
                title={`Feedback from ${lastPublishedCycle.name}`}
                reviewUrl={getReviewsUrl(
                  ReviewCategory.Performance,
                  String(lastPublishedCycle.id),
                  employee.id,
                )}
              />
            </Box>
          </TransitionCollapse>
          <Button
            onClick={() => setShowFeedback(!showFeedback)}
            variant="text"
            size="sm"
            px={0}
            mb="-8px"
          >
            {showFeedback ? 'Hide feedback' : 'View feedback'}
          </Button>
        </>
      )
    }

    return null
  }, [lastPublishedCycle, data, showFeedback, employee.id])

  return (
    <VStack space="s-32">
      <ProgressionSection data={employee} />
      {renderSectionTitle('Progression history')}
      {!performanceTimelineTable.error && <PerformanceTimeline employee={employee} />}
      {renderSectionTitle('Performance summary')}
      <Cell>
        <VStack space="s-32" width="100%">
          {performanceSummaryTitle}
          {recommendations && recommendations.length ? (
            <AdjustableTable
              name={TableNames.PerformanceSummary}
              loading={!latestGrade}
              row={getRow(gradesMap)}
              hideCount
              count={4}
              data={recommendations}
            />
          ) : null}
          {performanceTableSection}
          {overalFeedbackSection}
        </VStack>
      </Cell>
    </VStack>
  )
}

export default (props: PerformanceDataSidebarProps) => {
  const { lastPublishedCycle, isLoading } =
    useContext<PerformanceContextValue>(PerformanceContext)

  const { employee, isOpen, onClose } = props

  const subtitle = useMemo(() => {
    return (
      <HStack space="s-8">
        <Avatar image={getAvatarUrl(employee.avatar)} size={24}>
          {employee.avatar ? null : getInitials(employee.full_name)}
        </Avatar>
        <Text color={Color.FOREGROUND}>{employee.display_name}</Text>
      </HStack>
    )
  }, [employee])

  if (!lastPublishedCycle && !isLoading) {
    throw new Error(
      'Failed to determine last published cycle. Perhaps PerformanceProvider is missed or employee data currupted',
    )
  }

  return (
    <SideBar
      variant="wide"
      title="Performance Data"
      isOpen={isOpen}
      onClose={onClose}
      subtitle={subtitle}
    >
      {isLoading ? (
        <Skeleton width="100%" />
      ) : (
        <PerformanceDataSidebar {...props} lastPublishedCycle={lastPublishedCycle!} />
      )}
    </SideBar>
  )
}
