import { Box, Flex, Skeleton, Token } from '@revolut/ui-kit'
import { useGetCalibrarionStats } from '@src/api/talent'
import Stat, { StatProps } from '@src/components/Stat/Stat'
import { useTableReturnType } from '@src/components/Table/hooks'
import { FilterByInterface } from '@src/interfaces/data'
import {
  TalentPerformanceInterface,
  TalentPerformanceStatsInterface,
} from '@src/interfaces/talent/performance'
import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

export enum FilterGroups {
  all = 'all',
  average = 'Consistent performing',
  historicalUP = 'Historical underperformance',
  hiddenHistoricalUP = 'Hidden historical underperformance',
  potentialInflation = 'Potential inflation',
}

const StatWithPointer = styled(Stat)`
  cursor: pointer;
`

interface ClickableStatProps extends Omit<StatProps, 'theme'> {
  onClick: () => void
  value: ReactNode
  higlighted?: boolean
}

export const ClickableStat = ({
  onClick,
  label,
  value,
  higlighted = false,
  ...rest
}: ClickableStatProps) => {
  return (
    <StatWithPointer
      aria-label={`show only ${label}`}
      onClick={onClick}
      label={label}
      val={typeof value === 'undefined' ? <Skeleton width="50px" height="28px" /> : value}
      px="s-16"
      py="s-8"
      backgroundColor={higlighted ? Token.color.segmentedBackground : undefined}
      borderRadius={Token.radius.r16}
      {...rest}
    />
  )
}

export const useTalentGroupFilters = (
  table: useTableReturnType<
    TalentPerformanceInterface,
    TalentPerformanceStatsInterface,
    {}
  >,
) => {
  const columnName = 'calibration_flags'
  const [selectedGroup, setSelectedGroup] = useState<FilterGroups | undefined>()
  const { data: stats } = useGetCalibrarionStats(
    table.filterBy.filter(f => f.columnName !== columnName),
  )

  const filtersByGroup: Record<
    FilterGroups,
    {
      filter: FilterByInterface
      label: string
      statKey: keyof TalentPerformanceStatsInterface
    }
  > = {
    [FilterGroups.all]: {
      filter: {
        filters: [],
        columnName,
      },
      label: 'All employees',
      statKey: 'headcount',
    },
    [FilterGroups.average]: {
      filter: {
        filters: [{ id: FilterGroups.average, name: FilterGroups.average }],
        columnName,
      },
      label: 'Consistent Performing',
      statKey: 'consistent_average_plus',
    },
    [FilterGroups.historicalUP]: {
      filter: {
        filters: [{ id: FilterGroups.historicalUP, name: FilterGroups.historicalUP }],
        columnName,
      },
      label: 'Historical UP',
      statKey: 'historical_underperformance',
    },
    [FilterGroups.hiddenHistoricalUP]: {
      filter: {
        filters: [
          { id: FilterGroups.hiddenHistoricalUP, name: FilterGroups.hiddenHistoricalUP },
        ],
        columnName,
      },
      label: 'Hidden Historical UP',
      statKey: 'hidden_historical_underperformance',
    },
    [FilterGroups.potentialInflation]: {
      filter: {
        filters: [
          { id: FilterGroups.potentialInflation, name: FilterGroups.potentialInflation },
        ],
        columnName,
      },
      label: 'Potential inflation',
      statKey: 'potential_inflation',
    },
  }

  const renderGroupFilter = (group: FilterGroups) => {
    const { filter, label, statKey } = filtersByGroup[group]
    const applyFilter = () => {
      setSelectedGroup(group)
      table.onFilterChange(filter)
    }
    const val = stats?.[statKey]

    return (
      <Box>
        <ClickableStat
          aria-label={`show only ${group}`}
          onClick={applyFilter}
          label={label}
          value={val}
          higlighted={selectedGroup === group}
          px="s-16"
          py="s-8"
          borderRadius={Token.radius.r16}
        />
      </Box>
    )
  }

  useEffect(() => {
    const flagsFilter = table.filterBy.find(f => f.columnName === columnName)
    if (!flagsFilter || !flagsFilter.filters.length) {
      setSelectedGroup(FilterGroups.all)
    } else if (flagsFilter.filters.length > 1) {
      setSelectedGroup(undefined)
    } else {
      const filterValue = flagsFilter.filters[0].id as FilterGroups
      if (filtersByGroup[filterValue]) {
        setSelectedGroup(filterValue)
      }
    }
  }, [table.filterBy])

  return (
    <Flex flex={1}>
      {renderGroupFilter(FilterGroups.all)}
      {renderGroupFilter(FilterGroups.average)}
      {renderGroupFilter(FilterGroups.historicalUP)}
      {renderGroupFilter(FilterGroups.hiddenHistoricalUP)}
      {renderGroupFilter(FilterGroups.potentialInflation)}
    </Flex>
  )
}
