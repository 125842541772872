import React from 'react'
import { FinalGrade } from '@src/interfaces/performance'
import { TextProps, Text, Token } from '@revolut/ui-kit'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'

interface Props extends TextProps {
  value: FinalGrade
}

const getColorsAndGrade = (gradesMap: GradesMapInterface, grade?: FinalGrade) => {
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: Token.color.red,
        grade: gradesMap[FinalGrade.Poor],
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        grade: gradesMap[FinalGrade.AverageMinus],
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.foreground,
        grade: gradesMap[FinalGrade.AveragePlus],
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.green,
        grade: gradesMap[FinalGrade.Strong],
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.green,
        grade: gradesMap[FinalGrade.Exceptional],
      }
    default:
      return {
        color: Token.color.foreground,
        grade: 'N/A',
      }
  }
}

const RecommendationGrade = ({ value, ...rest }: Props) => {
  const gradesMap = useGetReviewGradesMap()
  const config = getColorsAndGrade(gradesMap, value)
  return (
    <Text use="div" color={config.color} {...rest}>
      {config.grade}
    </Text>
  )
}

export default RecommendationGrade
