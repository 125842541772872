import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'

export interface GradesMapInterface {
  [key: string]: string
}
export const useGetReviewGradesMap = () => {
  const { options: gradesOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.grades,
  )
  const gradesMap: GradesMapInterface = {}
  gradesOptions.forEach(option => {
    gradesMap[option.value.id] = option.value.name
  })

  return gradesMap
}
