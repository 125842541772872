import { Radio, RadioGroup, Token, VStack, Text, Box, chain } from '@revolut/ui-kit'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import React, { useState } from 'react'
import styled from 'styled-components'
import SectionTitle from '../../components/SectionTitle'

const FitWrapper = styled(Box)`
  margin-top: -20px;
  margin-right: -10px;
  margin-left: -10px;
`

export const SummaryTab = () => {
  const [pros, setPros] = useState<string[]>([
    "Wade's product thinking skills are Intermediate level",
    'He makes appropriate functional trade-offs. He also considers consistency, inclusivity and compliance',
    'Wade priorities solutions with maximum business value. He is considered an industry leader in his domain.',
  ])
  const [cons, setCons] = useState<string[]>([
    'Wade needs to give more attention to including and thinking about all the corner cases',
  ])
  const recomendations = [
    {
      title: 'Unsatisfactory',
      description: 'Far below expectations',
      color: Token.color.red,
    },
    {
      title: 'Average –',
      description: 'Below expectations and needs improvement',
      color: Token.color.warning,
    },
    {
      title: 'Performing',
      description: 'Meets expectations',
      color: Token.color.foreground,
    },
    {
      title: 'Strong',
      description: 'Exceeds expectations',
      color: Token.color.green,
    },
    {
      title: 'Exceptional',
      description: 'Greatly exceeds expectations',
      color: Token.color.green,
    },
  ]

  return (
    <VStack space="s-24">
      <VStack>
        <SectionTitle title="Performance recommendation" />
        <RadioGroup defaultValue={'Strong'}>
          {group => (
            <VStack space="s-16">
              {recomendations.map((recommendation, idx) => (
                <Radio
                  {...group.getInputProps({ value: recommendation.title })}
                  key={idx}
                >
                  <Radio.Label color={recommendation.color} fontWeight={600}>
                    {recommendation.title}
                  </Radio.Label>
                  <Radio.Description>{recommendation.description}</Radio.Description>
                </Radio>
              ))}
            </VStack>
          )}
        </RadioGroup>
      </VStack>
      <Box>
        <SectionTitle title="Employee feedback" />
        <FitWrapper>
          <OverallFeedback
            title={chain([
              <Text variant="secondary" color={Token.color.foreground} key={1}>
                Wade Snow
              </Text>,
              <Text variant="secondary" color={Token.color.greyTone50} key={2}>
                LM
              </Text>,
            ])}
            name="Wade Snow"
            cons={cons}
            pros={pros}
            onChangeCons={input => setCons(input.split('/n'))}
            onChangePros={input => setPros(input.split('/n'))}
          />
        </FitWrapper>
      </Box>
    </VStack>
  )
}
