import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import {
  Avatar,
  Box,
  Flex,
  Item,
  Token,
  VStack,
  Group,
  Text,
  chain,
  Icon,
  Cell,
} from '@revolut/ui-kit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Stat from '@components/Stat/Stat'
import {
  getLatestGrade,
  usePerformanceChartData,
} from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceHistory/hooks'
import { Chart } from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { BarChart, ChevronRight, Timeline } from '@revolut/icons'
import {
  FinalGrade,
  PerformanceSelector,
  PerformanceTimelineStats,
  RequestFeedbackInterface,
  ReviewCategory,
  ReviewerRelation,
} from '@src/interfaces/performance'
import {
  getPerformanceTimelineStats,
  useGetPerformanceSelector,
} from '@src/api/performance'
import { LayoutTabProps } from '@src/pages/EmployeeProfile/Layout/common/types'
import { PerformanceLayoutCycleSelect } from '@src/pages/EmployeeProfile/Preview/Performance/Common/PerformanceLayoutCycleSelect'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { isBefore, subDays } from 'date-fns'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  checkIsProbationPeriod,
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { PermissionTypes } from '@src/store/auth/types'
import { ProgressionSection } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary'
import { PerformanceSection } from './PerformanceSection'
import { ProbationPipTimeline } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/ProbationPipTimeline'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PerformanceLayoutKeyDates } from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/PerformanceLayoutKeyDates'
import Reviewer from '@src/pages/EmployeeProfile/Preview/Performance/Common/Reviewer'
import { SummaryGoalsObsoleteReview } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryGoalsObsoleteReview'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { ObsoletePerformanceReview } from '@src/pages/EmployeeProfile/Layout/Performance/ObsoletePerformanceReview'
import { ProbationSummarySection } from '@src/pages/EmployeeProfile/Layout/Performance/ProbationSummarySection'
import { PerformanceSummarySection } from '@src/pages/EmployeeProfile/Layout/Performance/PerformanceSummarySection'
import { PerformanceTimeline } from '@src/pages/EmployeeProfile/Layout/Performance/PerformanceTimeline'
import { useGetPeriodTypes } from '@src/utils/performance'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import { getInverseColorsAndGrade } from '@components/PerformanceGrade/PerformanceGrade'
import PopupChart from '@components/Charts/BaseChart/PopupChart'
import { ProbationPipAdditionalInfo } from '@src/pages/EmployeeProfile/Layout/Performance/ProbationPipAdditionalInfo'
import { HeaderActions } from '@src/pages/EmployeeProfile/Layout/Performance/HeaderActions'
import { canViewProgressionComponent } from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { useGetReviewGradesMap } from '@src/utils/grades'

export const PerformanceLayoutTab = ({ data, stats }: LayoutTabProps) => {
  const { query } = useQuery(true)
  const [timelineStats, setTimelineStats] = useState<PerformanceTimelineStats | null>()
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceSelector>()
  const [performanceSelector, setPerformanceSelector] = useState<PerformanceSelector[]>()
  const [performanceLink, setPerformanceLink] = useState<string>()
  const [checkpoints, setCheckpoints] = useState<ProbationCheckpoints>()
  const [finalGrade, setFinalGrade] = useState<FinalGrade>()
  const { data: performanceSelectorData } = useGetPerformanceSelector(data.id)
  const user = useSelector(selectUser)

  const { data: feedback, refetch: refetchFeedbackList } = useGetAllFeedbackList(data.id)
  const gradesMap = useGetReviewGradesMap()

  const isProbationPeriod = checkIsProbationPeriod(data)
  const isPIPv2 =
    selectedPeriod?.category === ReviewCycleCategory.PIP && selectedPeriod.version === 2

  const canViewAllPerformanceStats = !!data?.field_options?.permissions?.includes(
    PermissionTypes.CanViewAllPerformanceStats,
  )
  const canViewPerformanceHistoryTab = !!data?.field_options?.permissions?.includes(
    PermissionTypes.ViewPerformanceHistoryTab,
  )
  const canViewProgression = canViewProgressionComponent(data)

  const isManager =
    data.line_manager?.id === user.id || data.quality_control?.id === user.id

  const initialCategory = useMemo(() => {
    if (isProbationPeriod) {
      return ReviewCategory.Probation
    }
    if (isPIPv2) {
      return ReviewCategory.PIP_V2
    }
    return ReviewCategory.Performance
  }, [isProbationPeriod, isPIPv2])
  const isProbationOrPip = selectedPeriod?.category !== ReviewCycleCategory.Performance

  const fetchPerformanceSelector = () => {
    if (performanceSelectorData) {
      const selectors = performanceSelectorData.filter(selector => {
        if (selector.category === ReviewCycleCategory.PIP) {
          return true
        }
        if (selector.category === ReviewCycleCategory.Probation) {
          // if Probation is going to start in a week or less, we want to pre-fill cycle selector with it
          const startDateAdjusted = subDays(new Date(selector.start_date_time), 7)
          return isBefore(startDateAdjusted, new Date())
        }
        return isBefore(new Date(selector.start_date_time), new Date())
      })

      const changeSelectedPeriod =
        !!selectedPeriod && !selectors.find(selector => selector.id === selectedPeriod.id)

      if (isProbationPeriod || query.cycle_id === 'null') {
        setSelectedPeriod(
          selectors.find(selector => selector.category === ReviewCycleCategory.Probation),
        )
      } else if ((!selectedPeriod || changeSelectedPeriod) && !isProbationPeriod) {
        if (query.cycle_id) {
          setSelectedPeriod(
            selectors.find(selector => {
              return `${selector.id}` === query.cycle_id
            }),
          )
        } else {
          setSelectedPeriod(
            selectors.find(selector => selector.performance_reviews_selected_cycle),
          )
        }
      }
      return selectors as OptionInterface[]
    }

    return []
  }

  useEffect(() => {
    const fetchSelector = () => {
      const result = fetchPerformanceSelector()
      setPerformanceSelector(result as PerformanceSelector[])
    }
    fetchSelector()
  }, [initialCategory, performanceSelectorData])

  useEffect(() => {
    if (isManager || canViewAllPerformanceStats) {
      getPerformanceTimelineStats(data.id)
        .then(response => setTimelineStats(response.data))
        .catch(() => setTimelineStats(null))
    }
  }, [isManager, canViewAllPerformanceStats])

  let periodBeforeOngoing: PerformanceSelector | undefined
  if (selectedPeriod?.offset === 0) {
    periodBeforeOngoing = performanceSelector?.find(el => el.offset === 1)
  }

  const onHistoryClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_TIMELINE.HISTORY, {
        id: data.id,
      }),
    )
  }
  const onPerformanceClick = () => {
    if (performanceLink) {
      navigateTo(performanceLink)
    }
  }

  const fetchFinalGrade = useCallback(async () => {
    if (selectedPeriod?.id === undefined) {
      return
    }

    const category =
      selectedPeriod?.category === ReviewCycleCategory.Probation
        ? ReviewCategory.Probation
        : selectedPeriod?.category === ReviewCycleCategory.PIP
        ? ReviewCategory.PIP_V2
        : ReviewCategory.Performance

    const resp = await getPerformanceReviewSummary(
      String(selectedPeriod.id),
      data.id,
      category,
      [
        {
          columnName: 'reviewer_relation',
          filters: [
            {
              id: ReviewerRelation.LineManager,
              name: ReviewerRelation.LineManager,
            },
            {
              id: ReviewerRelation.FunctionalManager,
              name: ReviewerRelation.FunctionalManager,
            },
          ],
        },
      ],
    )
    setFinalGrade(resp.data?.grade_after_calibration?.display_grade)
  }, [selectedPeriod?.id])

  useEffect(() => {
    fetchFinalGrade()
  }, [selectedPeriod, initialCategory, selectedPeriod?.id])

  const grade = getInverseColorsAndGrade(gradesMap, finalGrade)

  const { isNewFlow } = useGetPeriodTypes(selectedPeriod)

  const requestsContext = useFetcherPerformanceRequests({
    category: initialCategory,
    isNewFlow,
    id: data.id,
    performanceCycle: selectedPeriod,
  })
  const {
    data: requestsData,
    isLoading: fetchingRequests,
    refetch: refetchRequests,
  } = requestsContext
  const requests = isNewFlow
    ? (requestsData as NewFlowRequestsResponse | undefined)?.results
    : (requestsData as RequestFeedbackInterface[] | undefined)

  const { mappedChartData } = usePerformanceChartData(data, true)
  const chartIcon = <BarChart cursor="pointer" size={16} color={Token.color.greyTone50} />

  return (
    <VStack space="s-16">
      <Cell>
        <VStack space="s-16">
          <Flex flexDirection="row">
            <PerformanceLayoutCycleSelect
              selectedPeriod={selectedPeriod}
              performanceSelector={performanceSelector}
              onChange={selector => {
                setPerformanceLink(undefined)
                setSelectedPeriod(selector)
              }}
            />
            {!!timelineStats && (
              <Stat
                label={`Latest grade${
                  timelineStats?.cycle?.name ? ` (${timelineStats.cycle.name})` : ''
                }`}
                val={
                  <Flex alignItems="center">
                    <Box mr="s-8">{getLatestGrade(gradesMap, timelineStats)}</Box>
                    <PopupChart trigger={chartIcon}>
                      {() => (
                        <Chart
                          data={mappedChartData.grades}
                          id={data.id}
                          isRating={false}
                        />
                      )}
                    </PopupChart>
                  </Flex>
                }
                ml="s-32"
              />
            )}
          </Flex>
          <HeaderActions
            selectedPeriod={selectedPeriod}
            data={data}
            requests={requests}
            fetchingRequests={fetchingRequests}
            checkpoints={checkpoints}
            refetchRequests={refetchRequests}
          />
        </VStack>
      </Cell>
      <TwoColumnsLayout
        left={
          <>
            {selectedPeriod && (
              <Group>
                <Item>
                  <Item.Avatar>
                    <Avatar useIcon={<Icon name="Time" />} />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Timeline</Item.Title>
                    <Item.Description>Track events this review cycle</Item.Description>
                  </Item.Content>
                </Item>
                {!isNewFlow && !!selectedPeriod?.reviews.length && (
                  <Box p="s-16" pt={0}>
                    <PerformanceLayoutKeyDates reviews={selectedPeriod.reviews} />
                    {!!requests?.length && <Text variant="primary">Reviewers</Text>}
                    <Box pt="s-8">
                      <VStack gap="s-16">
                        {requests?.map(request => (
                          <Flex
                            key={request.id}
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Reviewer request={request} isNewFlow={isNewFlow} />
                          </Flex>
                        ))}
                      </VStack>
                    </Box>
                  </Box>
                )}
                {isNewFlow && (
                  <Box p="s-16" pt={0}>
                    {isProbationOrPip ? (
                      <ProbationPipTimeline
                        data={data}
                        selectedPeriod={selectedPeriod}
                        setCheckpoints={setCheckpoints}
                        performanceLink={performanceLink}
                      />
                    ) : (
                      <PerformanceTimeline
                        data={data}
                        selectedPeriod={selectedPeriod}
                        performanceLink={performanceLink}
                      />
                    )}
                  </Box>
                )}
              </Group>
            )}
            {canViewPerformanceHistoryTab && (
              <Group>
                <Item use="button" onClick={onHistoryClick}>
                  <Item.Avatar>
                    <Avatar useIcon={Timeline} />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>History</Item.Title>
                    <Item.Description>Your data for each review cycle</Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <ChevronRight color={Token.color.greyTone50} />
                  </Item.Side>
                </Item>

                <Box p="s-16" pt={0}>
                  <VStack>
                    {canViewProgression && (
                      <ProgressionSection
                        data={data}
                        renderHeader={false}
                        sticky
                        withBorder
                        noError
                      />
                    )}
                    <Box mt="s-16">
                      <PerformanceSection
                        data={data}
                        selectedCycle={selectedPeriod}
                        onRowClick={rowData => {
                          const clickedPeriod = performanceSelectorData?.find(
                            selector => selector.id === rowData.cycle.id,
                          )
                          if (clickedPeriod) {
                            setSelectedPeriod(clickedPeriod)
                          }
                        }}
                      />
                    </Box>
                  </VStack>
                </Box>
              </Group>
            )}
            {isProbationOrPip && selectedPeriod && (
              <ProbationPipAdditionalInfo selectedPeriod={selectedPeriod} data={data} />
            )}
          </>
        }
        right={
          <Group>
            <Item
              use={performanceLink ? 'button' : undefined}
              onClick={onPerformanceClick}
            >
              <Item.Avatar>
                <Avatar useIcon={BarChart} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Performance summary</Item.Title>
                <Item.Description>
                  {chain(
                    selectedPeriod?.name,
                    grade ? <Text color={grade.color}>{grade.grade}</Text> : undefined,
                  )}
                </Item.Description>
              </Item.Content>
              {performanceLink && (
                <Item.Side>
                  <ChevronRight color={Token.color.greyTone50} />
                </Item.Side>
              )}
            </Item>

            <Box p="s-16" pt={0}>
              <VStack space="s-16">
                {!isNewFlow && selectedPeriod && (
                  <>
                    <SummaryGoalsObsoleteReview
                      category={selectedPeriod.category}
                      employeeId={data.id}
                      reviewCycleId={selectedPeriod.id}
                    />
                    <ObsoletePerformanceReview
                      data={data}
                      selectedPeriod={selectedPeriod}
                      feedback={feedback}
                      refetchFeedbackList={refetchFeedbackList}
                    />
                  </>
                )}
                {selectedPeriod && (
                  <>
                    {selectedPeriod.category === ReviewCycleCategory.Performance ? (
                      <PerformanceSummarySection
                        data={data}
                        stats={stats}
                        selectedPeriod={selectedPeriod}
                        periodBeforeOngoing={periodBeforeOngoing}
                        setPerformanceLink={setPerformanceLink}
                        feedback={feedback}
                        refetchFeedbackList={refetchFeedbackList}
                      />
                    ) : (
                      <ProbationSummarySection
                        data={data}
                        selectedPeriod={selectedPeriod}
                        setPerformanceLink={setPerformanceLink}
                        feedback={feedback}
                        refetchFeedbackList={refetchFeedbackList}
                      />
                    )}
                  </>
                )}
              </VStack>
            </Box>
          </Group>
        }
      />
    </VStack>
  )
}
