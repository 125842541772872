import React from 'react'
import { Table, Token, Text } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import { GradesMapInterface } from '@src/utils/grades'

const TableCell = ({
  value,
  gradesMap,
  color,
  background,
}: {
  value: string
  gradesMap: GradesMapInterface
  color?: string
  background?: string
}) => {
  const getColor = () => {
    switch (value) {
      case gradesMap[FinalGrade.Poor]:
        return Token.color.error
      case gradesMap[FinalGrade.AverageMinus]:
        return Token.color.foreground
      case gradesMap[FinalGrade.Strong]:
      case gradesMap[FinalGrade.Exceptional]:
        return Token.color.success
      default:
        return Token.color.foreground
    }
  }
  return (
    <Table.Cell bg={background} borderColor={background}>
      <Text
        variant="small"
        color={color || getColor()}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {value}
      </Text>
    </Table.Cell>
  )
}

interface GradeColumnCellInerface {
  hofGrade: string
  unsatisfactory: string
  averageMinus: string
  averagePlus: string
  strong: string
  exception: string
}
type CellProps = { row: { values: GradeColumnCellInerface } }

export const getColumns = (gradesMap: GradesMapInterface) => [
  {
    Header: '',
    accessor: 'hofGrade',
    width: 70,
    Cell: ({ row }: CellProps) => (
      <TableCell
        value={row.values.hofGrade}
        color={Token.color.foreground}
        background={Token.color.greyTone5}
        gradesMap={gradesMap}
      />
    ),
  },
  {
    Header: gradesMap[FinalGrade.Poor],
    accessor: 'unsatisfactory',
    Cell: ({ row }: CellProps) => (
      <TableCell value={row.values.unsatisfactory} gradesMap={gradesMap} />
    ),
    width: 70,
  },
  {
    Header: gradesMap[FinalGrade.AverageMinus],
    accessor: 'averageMinus',
    Cell: ({ row }: CellProps) => (
      <TableCell value={row.values.averageMinus} gradesMap={gradesMap} />
    ),
    width: 70,
  },
  {
    Header: gradesMap[FinalGrade.AveragePlus],
    accessor: 'averagePlus',
    Cell: ({ row }: CellProps) => (
      <TableCell value={row.values.averagePlus} gradesMap={gradesMap} />
    ),
    width: 70,
  },
  {
    Header: gradesMap[FinalGrade.Strong],
    accessor: 'strong',
    Cell: ({ row }: CellProps) => (
      <TableCell value={row.values.strong} gradesMap={gradesMap} />
    ),
    width: 70,
  },
  {
    Header: gradesMap[FinalGrade.Exceptional],
    accessor: 'exception',
    Cell: ({ row }: CellProps) => (
      <TableCell value={row.values.exception} gradesMap={gradesMap} />
    ),
    width: 70,
  },
]

export const getData = (gradesMap: GradesMapInterface) => [
  {
    hofGrade: gradesMap[FinalGrade.Poor],
    unsatisfactory: gradesMap[FinalGrade.Poor],
    averageMinus: gradesMap[FinalGrade.AverageMinus],
    averagePlus: gradesMap[FinalGrade.AverageMinus],
    strong: gradesMap[FinalGrade.AveragePlus],
    exception: gradesMap[FinalGrade.AveragePlus],
  },
  {
    hofGrade: gradesMap[FinalGrade.AverageMinus],
    unsatisfactory: gradesMap[FinalGrade.Poor],
    averageMinus: gradesMap[FinalGrade.AverageMinus],
    averagePlus: gradesMap[FinalGrade.AveragePlus],
    strong: gradesMap[FinalGrade.AveragePlus],
    exception: gradesMap[FinalGrade.AveragePlus],
  },
  {
    hofGrade: gradesMap[FinalGrade.AveragePlus],
    unsatisfactory: gradesMap[FinalGrade.AverageMinus],
    averageMinus: gradesMap[FinalGrade.AverageMinus],
    averagePlus: gradesMap[FinalGrade.AveragePlus],
    strong: gradesMap[FinalGrade.AveragePlus],
    exception: gradesMap[FinalGrade.AveragePlus],
  },
  {
    hofGrade: gradesMap[FinalGrade.Strong],
    unsatisfactory: gradesMap[FinalGrade.AverageMinus],
    averageMinus: gradesMap[FinalGrade.AverageMinus],
    averagePlus: gradesMap[FinalGrade.Strong],
    strong: gradesMap[FinalGrade.Strong],
    exception: gradesMap[FinalGrade.Strong],
  },
  {
    hofGrade: gradesMap[FinalGrade.Exceptional],
    unsatisfactory: gradesMap[FinalGrade.AverageMinus],
    averageMinus: gradesMap[FinalGrade.AverageMinus],
    averagePlus: gradesMap[FinalGrade.Strong],
    strong: gradesMap[FinalGrade.Strong],
    exception: gradesMap[FinalGrade.Exceptional],
  },
]
