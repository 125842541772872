import React from 'react'
import { Color, Text, Token } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'

type Props = {
  grade?: FinalGrade | null
  inverse?: boolean
  className?: string
  withPending?: boolean
}

export const getColorsAndGrade = (
  gradesMap: GradesMapInterface,
  grade?: FinalGrade | null,
) => {
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: Token.color.background,
        background: Token.color.red,
        grade: gradesMap[FinalGrade.Poor],
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        background: Token.color.grey10,
        grade: gradesMap[FinalGrade.AverageMinus],
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: gradesMap[FinalGrade.AveragePlus],
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: gradesMap[FinalGrade.Strong],
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: gradesMap[FinalGrade.Exceptional],
      }
    default:
      return null
  }
}

export const getInverseColorsAndGrade = (
  gradesMap: GradesMapInterface,
  grade?: FinalGrade | null,
) => {
  switch (grade) {
    case FinalGrade.Poor:
      return {
        color: Token.color.red,
        background: 'transparent',
        grade: gradesMap[FinalGrade.Poor],
      }
    case FinalGrade.AverageMinus:
      return {
        color: Token.color.foreground,
        background: 'transparent',
        grade: gradesMap[FinalGrade.AverageMinus],
      }
    case FinalGrade.AveragePlus:
      return {
        color: Token.color.foreground,
        background: 'transparent',
        grade: gradesMap[FinalGrade.AveragePlus],
      }
    case FinalGrade.Strong:
      return {
        color: Token.color.green,
        background: 'transparent',
        grade: gradesMap[FinalGrade.Strong],
      }
    case FinalGrade.Exceptional:
      return {
        color: Token.color.green,
        background: 'transparent',
        grade: gradesMap[FinalGrade.Exceptional],
      }
    default:
      return null
  }
}

const PerformanceGrade = ({ grade, inverse, className, withPending }: Props) => {
  const gradesMap = useGetReviewGradesMap()
  const data = inverse
    ? getInverseColorsAndGrade(gradesMap, grade)
    : getColorsAndGrade(gradesMap, grade)

  if (!data) {
    if (withPending) {
      return (
        <Text className={className} color={Color.GREY_TONE_20}>
          Pending
        </Text>
      )
    }

    return null
  }

  return (
    <Text className={className} backgroundColor={data.background} color={data.color}>
      {data.grade}
    </Text>
  )
}

export default PerformanceGrade
